
:root {
    --link-1: #D65472;
    --link-2: #FFFF02;
    --link-3: gold;
    --text: #18272F;
    --counter: #30B67D;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.studio-about{
    padding: 120px 100px;
    background-color: #434343;
    height: 600px;
    @media (max-width: 1024px) {
        padding: 40px;
        height: auto;
    }
    .title{
        display: flex;
        h2{
            padding: 0 0 25px 0;
            color: white;
            font-family: "NeueHaasDisplayMedium";
            display: flex;
            flex-direction: column;
            &::after{
                content: ' ';
                margin-top: 10px;
                width: 100px;
                height: 2px;
                background-color: white;
            }

        }
    }
    p{
        color: white;
        width: 916px;
        @media (min-width: 1600px) {
        //    width: 70%;
        }
        @media (max-width: 1024px) {
           width: 100%;
        }
    }
}
.leadership{
    padding: 120px 100px;
    // height: 600px;
    background-color: #F1F0EB;
    @media (max-width: 1024px) {
        padding: 40px;
    }
    .title{
        display: flex;
        h2{
            padding: 0 0 25px 0;
            color: black;
            font-family: "NeueHaasDisplayMedium";
            display: flex;
            flex-direction: column;
            &::after{
                content: ' ';
                margin-top: 10px;
                width: 100px;
                height: 2px;
                background-color: black;
            }

        }
    }
    .cards{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 50px 0 0;
        @media (max-width: 1024px) {
            justify-content: space-evenly;
            flex-wrap: wrap;
            column-gap: 20px;
        }
        .card{
            width: 15%;
            height: 100%;
            @media (max-width: 1024px) {
                width: 45%;
                padding-bottom: 30px;
            }

            &:nth-child(5){
                padding-bottom: 0;
            }
            img{
                width: 100%;
                height: 270px;
                object-fit: cover;
                @media (min-width:1600px) {
                    height: 370px;
                }
                @media (max-width:1024px) {
                    height: 420px;
                }
                @media (max-width:550px) {
                    height: 200px;
                }
            }
            h3{
                color: black;
                font-family: "HelveticaNeue";
                font-size: 25px;
                padding: 30px 0 0;
                @media (max-width: 550px) {
                    font-size: 20px;
                    padding: 20px 0 0;
                }
            }
            p{
                color: #6C6C6C;
                padding-top: 5px;
                @media (max-width: 550px) {
                    font-size: 17px;
                }
            }
        }
    }
}

.imagen-temp{
    // padding: 1.75rem  1.75rem 0;
    padding-bottom: 1.75rem;
    @media (max-width: 1024px) {
        padding-bottom: 1.5rem;
        height: 450px;
    }
    @media (max-width:550px) {
        height: 350px;
        
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
   
}
.top-gray{
    width: 100%;
    height: 100px;
    background-color: black;
}
.detalle_video{
    height: 100vh;
    background-color: black;
    @media (max-width:1024px) {
        height: 60vh;
    }
}
.content_article{
    width: 100%;
    height: auto;
    padding: 120px 100px 50px;
    @media (max-width:1024px) {
        padding: 50px 40px 0;
    }
    .title{
        display: flex;
        h2{
            padding: 0 0 25px 0;
            color: black;
            font-family: "NeueHaasDisplayMedium";
            display: flex;
            flex-direction: column;
            font-weight: normal;
            &::after{
                content: ' ';
                margin-top: 10px;
                width: 100px;
                height: 2px;
                background-color: black;
            }

        }
    }
    .content_text{
        color: #6C6C6C;
        a{
            // font-family: HelveticaNeuemedium;
            color: black !important;
            text-decoration: none;
            
            // border-bottom: 4px solid #FFFF02;
            font-size: 23px;
            @media (max-width:550px) {
                font-size: 18px;
            }
            &::after{
                content: '';
                width: 0px;
                height: 2px;
                background-color: black;
                position: absolute;
                bottom: 0;
                left: 0;
                transition: width 0.8s ease;
            }
            u{
                text-decoration: none;
            }
            &:hover{
                &::after{
                    width: 100%;
                }
            }
        }
        ol{
            padding-left: 20px;
            li{
                font-family: "HelveticaNeue";
                font-size: 23px;
                @media (max-width: 550px) {
                    font-size: 18px;
                    line-height: 21.47px;
                }
            }
        }
    }
}
.contento-pagination{
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: #F1F0EB !important;
}

.pagination_boton{
    padding: 50px 100px;
    display: flex;
    justify-content: space-between;
    @media (max-width:1024px) {
        padding: 20px 40px 60px;
    }
    a{
        font-family: HelveticaNeuemedium;
        font-size: 23px;
        font-weight: 700;
        // position: absolute;
        background-color: transparent;
        color: black;
        bottom: 0;
        z-index: 1;
        padding: 0 15px;
        cursor: pointer;
        @media (max-width: 550px) {
            font-size: 20px;
        }
        @media (max-width: 390px) {
            font-size: 19px;
        }
        &::after{
            content: ' ';
            width: 100%;
            height: 15px;
            background-color: #FFFF02;
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: 0;
            transition: height 0.5s ease-in-out;
        }
        &:hover{
            &::after{
                height: 20px;
            } 
        }
    }
}